<script setup lang="ts">
import {
  CmsSectionSidebar,
  useCmsSection,
} from '@shopware-pwa/composables-next';

const props = defineProps<{
  content: CmsSectionSidebar;
}>();
const { getPositionContent } = useCmsSection(props.content);

const sidebarBlocks = getPositionContent('sidebar');
const mainBlocks = getPositionContent('main');
const mobileBehavior = computed(() => props.content.mobileBehavior);
</script>

<template>
  <!-- class="cms-section-sidebar flex flex-col md:block" -->
  <!-- // TODO: remove tailwind -->
  <div class="cms-section-sidebar">
    <div
      :class="{
        'inline-block align-top w-12/12 lg:w-3/12 order-2 sidebar-blocks':
          mobileBehavior !== 'hidden',
        'hidden md:block': mobileBehavior === 'hidden',
      }"
    >
      <CmsGenericBlock
        v-for="cmsBlock in sidebarBlocks"
        :key="cmsBlock.id"
        :content="cmsBlock"
      />
      <!-- class="overflow-auto" -->
    </div>
    <div
      class="inline-block w-12/12 md:w-9/12 order-2 main-blocs"
      :class="sidebarBlocks.length ? 'with-sidebar' : ''"
    >
      <CmsGenericBlock
        v-for="cmsBlock in mainBlocks"
        :key="cmsBlock.id"
        class="overflow-auto"
        :content="cmsBlock"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
// TODO: move code from here
.sidebar-blocks {
  display: inline-block;
  width: 100%;
  // margin-top: 40px;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }

  @media (min-width: 1024px) {
    width: 25%;
    max-height: 100vh;
    max-width: 340px;
    overflow: auto;

    position: sticky;
    top: 0;
  }

  :deep(.listing-filter-properties-filter-category) {
    margin-right: 0;
    width: 100%


  }

  :deep(.listing-filter-properties-filter-category .items-center)  {
      display: flex;
      justify-content: space-between
    }

}

.main-blocs {
  display: inline-block;
  width: 100%;

  @media (min-width: 1024px) {
    width: 75%;
  }
}

.with-sidebar {
  :deep(.listing) {
    @media (min-width: 1024px) {
      border-left: 4px solid #f1f2f3;
    }
  }
}
</style>
